<template>
    <AppContainer>
        <Services/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import Services from '@/components/dashboard/services/Services.vue'
export default {
  name: 'ServicesList',
  components: {
    AppContainer,
    Services
  }
}
</script>