<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <div class="lead-search">
                    <div class="form-group">
                        <select
                            class="form-control text-dark"
                        >
                            <option value="">All</option>
                            <option value="0">Booking</option>
                            <option value="1">Queue</option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-6">
                <div class="add-Ondetail">
                    <div class="date-time">
                        <span class="est-date"><a href="#"><img src="@/assets/images/icons/calendar.png"></a></span>
                        <span class="est-time"><a href="#"><img src="@/assets/images/icons/estm-time.png"></a></span>
                    </div>
                </div>
            </div> -->
            <div class="col-md-9">
                <div class="custom-inline text-right">
                    <!-- <a href="#" class="button add-btn mr-2"> Export <fa icon="download" /></a> -->
                    <router-link :to="{name:'addService'}" class="button rem-btn">Add new service <fa icon="plus"/></router-link>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" v-for="(cate,index) in categories" :key="index">
            <div class="col-12 col-md-8" v-if="cate.business_service.length > 0">
                <span v-if="noData=false"></span>
                <div class="blog-box">
                    <div class="blog-heading" v-on:click="accordion(index)"><h3 :id="(index)"><fa icon="bars" /> {{cate.title}}</h3></div>
                    <table class="table" :id="'cate'+index">
                        <tbody>
                            <tr v-for="service in cate.business_service" :key="service.id">
                                <!-- <th scope="row"><fa icon="bars" v-on:click="this.$router.push({ name: 'editService',params:{id:service.id}})" /></th> -->
                                <td class="servename">{{service.title}}</td>
                                <td>{{service.duration}}min</td>
                                <td class="price">${{service.cost}}</td>
                                <td class="text-center">
                                    <fa :icon="['far','edit']" v-on:click="this.$router.push({ name: 'editService',params:{id:service.id}})" />
                                    <fa v-on:click="deleteService(service.id)" :icon="['far','trash-alt']" class="text-danger ms-2" />
                                </td>
                            </tr>
                            <!-- <tr>
                                <th scope="row"><fa icon="bars" /></th>
                                <td class="servename">adasds</td>
                                <td>30min</td>
                                <td class="price">$ 25</td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row bg-white" v-if="noData">
            <div class="text-center p-md-5">
                <img src="../../../assets/images/services/no-service.svg" style="width: 100px">
                <h3 class="fw-normal">No service found</h3>

                <p class="mb-3">Click on the “Add new service” button to start posting</p>
                <router-link :to="{name:'addService'}" class="button rem-btn d-inline">Add new service <fa icon="plus"/></router-link>
            </div>
        </div>

        <!-- <div class="row justify-content-center">
            <div class="col-12 col-md-8">
                <div class="blog-box">
                    <div class="blog-heading"><h3><fa icon="bars" /> Nails</h3></div>
                    <table class="table">
                        <tbody>
                        <tr>
                            <th scope="row"><fa icon="bars" /></th>
                            <td class="servename">Manicure</td>
                            <td>30min</td>
                            <td class="price">$ 25</td>
                        </tr>
                        <tr>
                            <th scope="row"><fa icon="bars" /></th>
                            <td class="servename">Manicure</td>
                            <td>30min</td>
                            <td class="price">$ 25</td>
                        </tr>
                        <tr>
                            <th scope="row"><fa icon="bars" /></th>
                            <td class="servename">Manicure</td>
                            <td>30min</td>
                            <td class="price">$ 25</td>
                        </tr>
                        <tr>
                            <th scope="row"><fa icon="bars" /></th>
                            <td class="servename">Manicure</td>
                            <td>30min</td>
                            <td class="price">$ 25</td>
                        </tr>
                        <tr>
                            <th scope="row"><fa icon="bars" /></th>
                            <td class="servename">Manicure</td>
                            <td>30min</td>
                            <td class="price">$ 25</td>
                        </tr>
                        <tr>
                            <th scope="row"><fa icon="bars" /></th>
                            <td class="servename">Manicure</td>
                            <td>30min</td>
                            <td class="price">$ 25</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import { useToast } from "vue-toastification";
//import { useConfirm } from 'v3confirm'
export default {
    data(){
		return{
            categories: [],
            noData:true
		}
    },
    setup () {
        const toast = useToast();
        //const confirm = useConfirm()
        return {toast }
    },
    created() {
        this.getServicesWithCategories()
    },
    methods: {
        accordion(i){
            $("#cate"+i).slideToggle(80)
            $('#'+i).toggleClass('active')
        },
        getServicesWithCategories(){
            let thiss = this
            axios.get('businessCategories?action=with_business_services').then(function (response) {
                //console.log(response.data.data)
                if (response.data.status == 'success') {
                    thiss.categories = response.data.data
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.toast.error(error.response.data.message);
                    }
                }
            });
        },
        deleteService(id){
            let thiss = this
            this.$swal({
                title: 'Are you sure?',
                text: "You want to delete this!",
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true,
                customClass: {
                    confirmButton: 'rem-btn',
                    cancelButton: 'add-btn mr-3'
                },
                buttonsStyling: false
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get('deleteBusinessService/'+id).then(function (response) {
                        if (response.data.status == 'success') {
                            thiss.getServicesWithCategories()
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 422) {
                                this.toast.error(error.response.data.message);
                            }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                                thiss.$storage.removeStorageSync("user")
                                thiss.$storage.removeStorageSync("token")
                                thiss.$storage.removeStorageSync("business")
                                thiss.$router.push({ name: 'Home'})
                            }else if (error.response.status == 401) {
                                this.toast.error(error.response.data.message,{position: "top-right"});
                            }
                        }
                    });
                }
            })
        }
    },

}
</script>
<style scoped>
.swal2-styled{
    padding: 6px 25px;
    font-size: 12px !important;
}
.blog-heading h3.active:after {
    content: '\002B'
}
.blog-heading h3:after {
    content: '\2212';
    float: right
}
.lead-search .form-control{
    font-size: 14px;
}
a.rem-btn{
    padding: 12px 25px;
    font-size: 15px;
    text-transform: none;
    background: darkgray;
    color: #fff;
    border: 0;
}
.blog-heading{
    padding: 14px 9px;
}
.table td{
    padding: 0.5rem;
}
@media screen and (max-width: 767px) {
    .lead-search .form-control {
        margin-left: 0px ;
    }
}
</style>